import { fmtDate } from '@bidease/ui-kit';
import { cdeebeeHelpers } from '@recats/cdeebee';

import {
  EnumColumnDataTableTypeList,
  EnumOrderingDirection,
  EnumCampaignState,
  EnumAttributionType,
  EnumGroupingKey,
  EnumStatsColumnName,
} from 'models/objects';
import { IUIModel } from 'models/ui';

import { normalizeUrlFilter, checkBoolean, checkNumber } from 'normalize/ui';

import { getApiVersion } from 'helpers/utils';

import * as Enum from 'constants/enum';
import { EnumLocalStorageKey } from 'constants/enum';

import types from 'actions/types';

export const EnumColumnSettingsDefault = {
  [EnumColumnDataTableTypeList.campaignList]: 'DefaultCampaignList',
  [EnumColumnDataTableTypeList.advertiserList]: 'DefaultAdvertiserList',
  [EnumColumnDataTableTypeList.campaignDetails]: 'DefaultCampaignDetails',
};

const getLocalStorage = () => {
  if (import.meta.env.SSR) {
    return { getItem: () => '', };
  }
  return localStorage;
};

export const EnumColumnSettings = {
  [EnumColumnDataTableTypeList.campaignList]: (
    getLocalStorage().getItem(EnumColumnDataTableTypeList.campaignList)
    || EnumColumnSettingsDefault[EnumColumnDataTableTypeList.campaignList]
  ),
  [EnumColumnDataTableTypeList.advertiserList]: (
    getLocalStorage().getItem(EnumColumnDataTableTypeList.advertiserList)
    || EnumColumnSettingsDefault[EnumColumnDataTableTypeList.advertiserList]
  ),
  [EnumColumnDataTableTypeList.campaignDetails]: (
    getLocalStorage().getItem(EnumColumnDataTableTypeList.campaignDetails)
    || EnumColumnSettingsDefault[EnumColumnDataTableTypeList.campaignDetails]
  ),
};

export function defaultGlobalStatsFilterParseUrl() {
  return {
    fromDate: normalizeUrlFilter.get(
      Enum.EnumFilterURLList.fromDate,
      fmtDate.toUTCISOFromSettedTZ(fmtDate.startOfDay(fmtDate.getZonedDate()))
    ),
    toDate: normalizeUrlFilter.get(
      Enum.EnumFilterURLList.toDate,
      fmtDate.toUTCISOFromSettedTZ(fmtDate.endOfDay(fmtDate.getZonedDate()))
    ),

    useReklFigures: getLocalStorage().getItem(EnumLocalStorageKey.useReklFigures) === 'true',

    selectionIDList: [],
    selectionIDGroupList: [],

    nameContains: normalizeUrlFilter.get(Enum.EnumFilterURLList.nameContains, ''),

    listsOrdering: {
      orderingList: [
        {
          orderingKey: normalizeUrlFilter.get(Enum.EnumFilterURLList.listOrdering_orderingKey, ''),
          orderingDirection: normalizeUrlFilter.get(Enum.EnumFilterURLList.listOrdering_orderingDirection, ''),
        },
      ],
    },
    statsGroup: {
      groupingKeyList: normalizeUrlFilter.get(Enum.EnumFilterURLList.statsGrouping_groupingKeyList, [EnumGroupingKey.application]),
    },
    statsFilter: {
      targetingContainerState: normalizeUrlFilter.get(Enum.EnumFilterURLList.statsFilter_targetingContainerState, null),
      campaignIDList: normalizeUrlFilter.getCampaignIDList(Enum.EnumFilterURLList.statsFilter_campaignIDList, []),
      campaignStates: normalizeUrlFilter.get(Enum.EnumFilterURLList.statsFilter_campaignStates, [EnumCampaignState.active, EnumCampaignState.pausedByRevenueCap]),
      managerIDList: normalizeUrlFilter.get(Enum.EnumFilterURLList.statsFilter_managerIDList, []),
      reklIDList: normalizeUrlFilter.get(Enum.EnumFilterURLList.statsFilter_reklIDList, []),
      filterList: normalizeUrlFilter.get(Enum.EnumFilterURLList.statsFilter_filterList, []),
      attributionType: normalizeUrlFilter.get(Enum.EnumFilterURLList.statsFilter_attributionType, EnumAttributionType.byImpression),
      productIDList: normalizeUrlFilter.get(Enum.EnumFilterURLList.statsFilter_productList, []),
      search: '',
      onlyFavoriteRekls: true,
      archivedCampaigns: checkBoolean(normalizeUrlFilter.get(Enum.EnumFilterURLList.statsFilter_archivedCampaign, false)),
      includeInactiveProducts: checkBoolean(normalizeUrlFilter.get(Enum.EnumFilterURLList.statsFilter_nativeProduct, false)),
    },
    statsOrdering: {
      orderingList: [
        {
          orderingKey: normalizeUrlFilter.get(Enum.EnumFilterURLList.statsOrdering_orderingKey, EnumStatsColumnName.conversions),
          orderingDirection: normalizeUrlFilter.get(
            Enum.EnumFilterURLList.statsOrdering_orderingDirection, EnumOrderingDirection.desc
          ),
        },
      ],
    }
  };
}

export const INITIAL_STATE = {
  internalError: { show: false, requestArray: [] },
  apiVersion: getApiVersion(),
  columnSettings: EnumColumnSettings,
  campaignSettings: {
    graphOpen: checkBoolean(normalizeUrlFilter.get(Enum.EnumFilterURLList.cmpGraphOpen, false)),
    includeNonPayableEvents: false,
    onlyUniqEvents: false,
  },
  conversionSettings: {
    retentionDays: checkNumber(normalizeUrlFilter.get(Enum.EnumFilterURLList.retentionDays, null)),
    roasDays: checkNumber(normalizeUrlFilter.get(Enum.EnumFilterURLList.roasDays, null)),
    initialAttributionTypeList: [],
  },
  frequencyAutoUpdate: {
    frequencyDetail: null,
    frequencyList: null,
    frequencyAdvertisers: null,
  },
  globalStatsFilter: defaultGlobalStatsFilterParseUrl(),
};

export default function(state: IUIModel = INITIAL_STATE, action: any) {
  const { type, payload } = action;

  switch (type) {
    case types.UI_INTERNAL_ERROR:
      return { ...state, internalError: payload };
    case types.UI_CHANGE_VALUE:
      return cdeebeeHelpers.batchingUpdate(state, payload.valueList, payload.preBatchingPath);
    case types.LOCATION_CHANGE: {
      // не меняем значение из стора + не отображем его в урле
      const { statsFilter: { onlyFavoriteRekls, ...otherStatsFilter }, ...globalStatsFilter } = defaultGlobalStatsFilterParseUrl();
      return {
        ...state,
        globalStatsFilter: {
          ...globalStatsFilter,
          statsFilter: {
            onlyFavoriteRekls: state.globalStatsFilter.statsFilter.onlyFavoriteRekls,
            ...otherStatsFilter,
          }
        },
        apiVersion: getApiVersion(),
        frequencyAutoUpdate: {
          ...state.frequencyAutoUpdate,
          frequencyDetail: null,
        },
      };
    }
    default:
      return state;
  }
}
