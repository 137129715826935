import { combineReducers } from 'redux';
import { cdeebee, requestManager } from '@recats/cdeebee';
import creatives from './creatives';
import ui from './ui';
import notifications from './notifications';
import summary from './summary';
import pretend from './pretend';

export default combineReducers({
  cdeebee,
  requestManager,
  creatives,
  ui,
  notifications,
  summary,
  pretend,
});
