import { cdeebeeTypes } from '@recats/cdeebee';

import API from 'constants/api';
import localize from 'constants/localize';
import { EnumDebounce, EnumInfoType } from 'constants/enum';
import { EnumResponseStatus, ErrorLocalize } from 'constants/errors';

import { TypedDispatch } from 'store';

import { IGetState } from 'models/utils';

import { createNotification } from 'actions/notifications';
import { IAction } from './types';

const WORKED_API = [
  API.creativeUpdateRequestDto,
  API.campaignUpdateRequestDto,

  API.adminMarkupCreateRequestDto,
  API.markupCreateRequestDto,

  API.adminMarkupUpdateRequestDto,
  API.markupUpdateRequestDto,

  API.productUpdateRequestDto,

  API.userUpdatePasswordRequestDto,
  API.userUpdateRequestDto
];

export default ({ getState, dispatch }: { getState: IGetState; dispatch: TypedDispatch }) => (next: any) => (action: IAction<unknown>) => {
  const result = next(action);

  if (action.type === cdeebeeTypes.CDEEBEE_REQUESTMANAGER_SHIFT && WORKED_API.includes(action.payload.api)) {
    const { api, requestID } = action.payload;
    const { requestByApiUrl, errorHandler } = getState().requestManager;
    if (errorHandler[api] && errorHandler[api].requestID === requestID) {
      createNotification({ message: localize.SomethingWentWrong, liveTime: EnumDebounce.resetForm, type: EnumInfoType.error, requestID })(dispatch);
    } else if (requestByApiUrl[api] && requestByApiUrl[api].requestID === requestID) {
      if (requestByApiUrl[api].responseStatus === EnumResponseStatus.Success) {
        createNotification({ message: localize.ChangeSuccess, liveTime: EnumDebounce.resetForm, type: EnumInfoType.success, requestID })(dispatch);
      } else {
        createNotification({ message: ErrorLocalize[requestByApiUrl[api].responseStatus as keyof typeof ErrorLocalize]?.text, liveTime: EnumDebounce.resetForm, type: EnumInfoType.success, requestID })(dispatch);
      }
    }
  }
  return result;
};
