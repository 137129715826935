import { cdeebeeTypes } from '@recats/cdeebee';
import { getApiVersion, resetWindowCache, setApiVersion } from 'helpers/utils';

import { IGetState } from 'models/utils';

import { IAction } from './types';

export default ({ getState }: { getState: IGetState }) => (next: any) => async (action: IAction<unknown>) => {
  const result = next(action);

  if (action.type === cdeebeeTypes.CDEEBEEE_UPDATE) {
    if (action.payload?.cleanResponse) {
      const { apiVersion, expectedUiVersion } = action.payload.cleanResponse;

      if (expectedUiVersion && expectedUiVersion > import.meta.env.REACT_APP_BUILD_VERSION) {
        await resetWindowCache(false);
      }

      if (!getApiVersion()) {
        setApiVersion(apiVersion);
      } else {
        if (apiVersion !== getState().ui.apiVersion || apiVersion !== getApiVersion()) {
          setApiVersion(apiVersion);
          await resetWindowCache(true);
        }
      }
    }
  }
  return result;
};
