import {
  EnumName,
  EnumProductType,
  EnumAttributionType,
  EnumTrackerCallType,
  EnumCampaignStatus,
  EnumFilterEntityType,
  EnumConnectionType,
  EnumTargetingContainerState,
  EnumDeviceType,
  EnumUserRole,
  EnumGender,
  EnumSegmentStatus,
  EnumCreationType,
  EnumStatsColumnName,
  EnumInitialAttributionTypeList,
  EnumGroupingKey,
  EnumMLearnTaskDtoStatus,
  EnumAdType,
  EnumBundleType,
  EnumOrganizationPaymentStatus,
  EnumOrganizationPaymentSource,
  EnumCreativeViewabilityProvider,
  EnumCreativeApproveStatus,
  EnumPaceKeeperStatus,
  EnumResourceType,
  EnumMarkupVariableType,
  EnumProductStatus,
  EnumReportStatus,
  EnumProductInitialAttributionType,
} from 'models/objects';

export default {
  NullableValue: '-',
  None: 'None',
  NoImage: 'No image',
  SignIn: 'Sign in',
  PleaseSignIn: 'Please, sign in',
  VerificationWith2FACode: 'Please enter the code which was send to your email address to continue and input for 6 symbols',
  AccountDisabled: 'Account suspended',
  Settings: 'Settings',
  Required: 'Required',
  Timezone: 'Timezone',
  Loading: 'Loading',
  Calculating: 'Calculating',
  LoadMore: 'Load more',
  CurrentPassword: 'Current password',
  NewPassword: 'New password',
  RepeatNewPassword: 'Repeat new password',
  ForgotPassword: 'Forgot password?',
  ChangeSuccess: 'Changes saved successfully',
  VerificationCode: 'Verification code',
  Balance: 'Balance',
  Billing: 'Billing',
  Home: 'Home',
  Segment: 'Segment',
  Segments: 'Segments',
  Reports: 'Reports',
  Models: 'Models',
  Manager: 'Manager',
  key: 'Key',
  value: 'Value',
  to: 'To',
  _entities: ' entities',

  // Actions
  Gallery: 'Gallery',
  ReloadPage: 'Reload the page',
  Upload: 'Upload',
  Submit: 'Submit',
  Logout: 'Logout',
  Restore: 'Restore',
  RestoreAccess: 'Restore access',
  Delete: 'Delete',
  Remove: 'Remove',
  Create: 'Create',
  Save: 'Save',
  Expand: 'Expand',
  Archive: 'Archive',
  Resume: 'Resume',
  Download: 'Download',
  Details: 'Details',
  SaveCampaign: 'Save campaign',
  SaveProduct: 'Save product',
  NewProduct: 'New product',
  Publish: 'Publish',
  Yes: 'Yes',
  No: 'No',
  Use: 'Use',
  Used: 'Used',
  Add: 'Add',
  AddFilter: 'Add filter',
  Added: 'Added',
  AddNew: 'Add new',
  SaveChanges: 'Save changes',
  AddGroup: 'Add group',
  SaveNewPassword: 'Save new password',
  SetNewPassword: 'Set New Password',
  Next: 'Next',
  Reject: 'Reject',
  Apply: 'Apply',
  Approve: 'Approve',
  Check: 'Check',
  Update: 'Update',
  Reset: 'Reset',
  ClearList: 'Clear list',
  Cancel: 'Cancel',
  Edit: 'Edit',
  Copy: 'Copy',
  Pretend: 'Pretend',
  Important: 'Important',
  not: 'not',
  NoAutoRefresh: 'No Refresh',
  Include: 'Include',
  Exclude: 'Exclude',
  Selected: 'Selected',
  Select: 'Select',
  Enable: 'Enable',
  Disable: 'Disable',
  Active: 'Active',
  Available: 'Available',
  ApproveCreatives: 'Approve creatives',
  SetupVideoSizeAllowed: 'Setup video size allowed',
  // [[END]] Actions
  PersonalData: 'Personal data',
  ChangePassword: 'Change password',
  // Fields
  Title: 'Title',
  Description: 'Description',
  Notes: 'Notes',
  Rewarded: 'Rewarded',
  Price: 'Price',
  Rating: 'Rating',
  Type: 'Type',
  Orientation: 'Orientation',
  Conversions: 'Convs',
  GoalCode: 'Goal code',
  GoalTarget: 'Target value',
  PayableUnique: 'Payable unique',
  Payout: 'Payout',
  RevenueShare: 'Revenue share',
  PayoutDays: 'Payout days',
  Dates: 'Dates',
  SelectRange: 'Select date range',
  StartDate: 'Start date',
  StopDate: 'Stop date',
  ExportDate: 'Export date',
  CreatedAt: 'Created at',
  CompletedAt: 'Completed at',
  Email: 'E-mail',
  SponsoredBy: 'Sponsored by',
  CallToActionText: 'Call to Action Text',
  FirstName: 'First name',
  LastName: 'Last name',
  ZipCode: 'ZIP code',
  State: 'State',
  Country: 'Country',
  City: 'City',
  OS: 'OS',
  Permissions: 'Permissions',
  Items: 'Items',
  Name: 'Name',
  Operator: 'Operator',
  Value: 'Value',
  Password: 'Password',
  SelectProductType: 'Product type',
  Coppa: '13+ (Coppa)',
  DeviceTypeAndOSVersions: 'Device type and os versions',
  SelectProduct: 'Select product',
  Domain: 'Advertiser domain',
  CustomUrl: 'Custom url',
  ImpressionShort: 'Imp.',
  TrackingPlatform: 'tracking platform',
  SKADTracker: 'SKAdNetwork conversion tracker',
  Search: 'Search',
  Min: 'Min',
  MinValue: 'Min value',
  Max: 'Max',
  MaxValue: 'Max value',
  MinAge: 'Min age',
  MaxAge: 'Max age',
  HourlyPaceLimit: 'Pace limit per hour',
  DailyRevenueLimitProduct: 'Daily revenue limit product',
  DynamicMarginManagement: 'Dynamic margin management',
  MinimumMargin: 'Minimum margin, %',
  DailyRevenueLimitCampaign: 'Daily revenue limit campaign',
  RevenueLimit: 'Product revenue budget',
  TotalProductRevenue: 'Total product revenue',
  PayoutCPM: 'Payout for 1k impression',
  AgencyMargin: 'Agency margin, %',
  ProductMargin: 'Product margin',
  EnableAgencyMargin: 'Enable agency margin',
  EnableCustomAgencyMagin: 'Enable custom agency margin',
  PayoutCPC: 'Payout for click',
  Retargeting: 'Retargeting',
  UseMobileIndentifier: 'Use mobile identifier',
  PMPDealList: 'PMP Deal list',
  Interests: 'Interests',
  MaxImpPosLength: 'ImpPos session length',
  MaxImpPosition: 'ImpPos maximum impressions',
  PredictorHash: 'Predictor Hash',
  SelectedModel: 'Selected Model',
  Multipliers: 'Multipliers',
  Multiplier: 'Multiplier',
  Identifier: 'Identifier',
  IFA: 'IFA',
  NonIFA: 'NonIFA',
  Probability: 'Probability',
  Range: 'Range',
  UseAutoBL: 'Auto Blacklisting',
  UseBalancer: 'Use balancer',
  AvgCPITreshold: 'Avg CPI multiplier threshold',
  DeviceAgeMinDays: 'Device age min days',
  DeviceAgeMaxDays: 'Device age max days',
  CustomTags: 'Custom Tags',
  // [[END]] Fields

  // Permission
  AddCreative: 'Add creative',
  AddRekl: 'Add advertiser',
  AddSegment: 'Add segment',
  AddTemplate: 'Add template',
  AddOrganization: 'Add organization',
  // [[END]] Permission

  BadRequest: 'Bad request',
  PageNotFound: 'Page not found',
  NoMarkupVariables: 'Add these fields if you need send additional values in markup variables',
  NoCreativeVariables: 'Add these fields if you need send additional values in creative variables',
  NoMarkupTestVariables: 'Add these fields if you need send additional values in markup test variables',
  NoMarkupResource: 'Add these fields if you need send additional values in resource list',

  Organization: 'Organization',
  TableCustomization: 'Table customization',
  Preset: 'Preset',
  Attribution: 'Attribution',
  Product: 'Product',
  Stats: 'Stats',
  StatSignificance: 'Stat significance',
  Users: 'Users',
  Rekl: 'Advertiser',
  ReklNotFound: 'Advertiser not found',
  Creatives: 'Creatives',
  CreativeList : 'Creative list',
  Interstitial: 'Interstitial',
  Targeting: 'Targeting',
  TargetUrlHttp: 'Target url',
  TargetUrlMarket: 'Application store url',
  Exchanges: 'Exchanges',
  Countries: 'Countries',
  Cities: 'Cities',
  Tracking: 'Tracking',
  ProductDailyRevenueLimitBadge: 'A',
  ProductPausedByRevenueBadge: 'PR',
  UseExtendedPlatformAdFeatures: 'Use Extended Platform Ad features',
  RequireStrictGEODetection: 'Require strict GEO detection',
  SKAdNetwork: 'SKAdNetwork',
  BasicInfo: 'Basic Info',
  SSP: 'SSP',
  Owner: 'Owner',
  Status: 'Status',
  items: 'items',
  ThereAreNoItems: 'No items',

  // Restore Password
  PasswordResetText: 'A letter with reset link was sent to your email, check out your inbox and follow the instructions.',
  GoToLoginPage: 'Go to login page',

  // Segments
  SegmentRemoveConfirmation: (name: string) => `Please confirm you want to remove segment <b>${name}</b>. This action couldn't be undone`,
  SegmentsTargeting: 'Segments targeting',
  SegmentName: 'Segment name',
  // [[##END]] Segments

  // Campaigns
  NoCampaigns: 'No campaigns',
  AddCampaign: 'Add campaign',
  BackCampaign: 'Back to campaign',
  AddProductToCampaign: 'Add product',
  FromCampaignStart: 'From start',
  CampaignName: 'Campaign name',
  CampaignAdvertiserFacedTitle: 'Advertiser faced title',
  Campaign: 'Campaign',
  CampaignInfo: 'Campaign info',
  CampaignList: 'Campaign list',
  CampaignListLoading: 'Campaign list loading...',
  LinkedToCampaigns: 'Linked to campaigns',

  eCPA: 'eCPA',
  Auto: 'Auto',
  Optimization: 'Optimization',
  Predictor: 'Predictor',
  WhiteBlackLists: 'White & Black lists',
  BudgetAndBidSettings: 'Budget & BidCPM',
  NoStatsData: 'No stats for selected time period',
  RetentionDays: 'Retention days',
  AttributionTypes: 'Attribution types',
  RoasDays: 'ROAS days',
  IncludeNonPayableEvents: 'All events',
  OnlyUniqEvents: 'Only unique events',
  ShowDataWhere: 'Show data where',
  ShowAllData: 'Show all data',
  ShowAll: 'Show all',
  Hide: 'Hide',
  ActiveOnly: 'Active',
  ShowArchived: 'Archived',
  Pause: 'Pause',
  Paused: 'Paused',
  Start: 'Start',
  AllowUseDataFromCampaingForModel: 'Allow use data from this campaign for Models',
  IncludeInstalls: 'Include installs',
  OtherGoals: 'Other goals',
  Others: 'Others',

  // Users
  PersonalInfoChangeSuccess: 'Personal info changed successfully',
  PasswordChangeSuccess: 'Password changed successfully',
  NewUser: 'New User',
  EditUser: 'Edit User',

  // WBList Items
  ExchangeList: 'Exchanges',
  BundleGenreList: 'Bundle genres',
  CountryList: 'Countries',
  ApplicationsList: 'Applications',
  ApplicationsListBlack: 'Applications installed black',
  ApplicationsListGroupWhite: 'Applications installed group white',

  MinimumInstalledApplications: 'Minimum installed applications',
  IABCategories: 'iAB categories',
  OperationSystemVersionList: 'Operation System Version',
  OsListWB: 'Operation System',
  ConnectionTypeList: 'Connection speed',
  MakeList: 'Make (vendor)',
  ModelList: 'Models',
  RegionList: 'Regions',
  CityList: 'Cities',
  CarrierList: 'Carriers',
  ZipList: 'Zip codes',
  IPList: 'IP list',
  LanguageList: 'Languages',
  UserAgentList: 'User agents',
  AppNameKeywords: 'App names keywords',
  ListOfBidCPM: 'List of BidCPM',
  ChangeBidCPM: 'Change BidCPM',
  // WBList Items

  // Advertisers
  Advertiser: 'Advertiser',
  AdvertiserEdit: 'Advertiser edit',
  Advertisers: 'Advertisers',
  InvalidAdvertiserID: 'Invalid advertiser',
  NoAdvertisers: 'No advertiser',
  IncludeInactiveProducts: 'Archived',
  Favorites: 'Favorites',
  AddToFavorites: 'Add to favorites',
  RemoveFromFavorites: 'Remove from favorites',
  ShareToAdvertiserUser: 'Share to advertiser user',

  // Billing
  TaxInformation: 'Tax information',
  CreateNewPayment: 'Create new payment',
  GoToBilling: 'Go to Billing',
  PaymentSuccess: 'Payment processing',
  PaymentCancel: 'Payment cancel',
  PaymentUnexpected: 'Payment unexpected status',
  OnlyMine: 'Only mine',
  PaymentMethod: 'Payment method',

  targetingContainerState: 'Targeting',

  // EnumGroupingKey
  [EnumGroupingKey.adType]: 'Ad type',
  [EnumGroupingKey.target]: 'Target',
  [EnumGroupingKey.convHour]: 'Hour of install',
  [EnumGroupingKey.interstitial]: 'Interstitial',
  [EnumGroupingKey.adBundle]: 'Ad bundle',
  [EnumGroupingKey.month]: 'Month',
  [EnumGroupingKey.language]: 'Language',
  [EnumGroupingKey.ipSubnetwork]: 'Subnetwork',
  [EnumGroupingKey.ipWideNetwork]: 'Wide Network',

  [EnumName.application]: 'Application',
  [EnumName.operationSystem]: 'Operation System',
  [EnumName.operationSystemVersion]: 'OS Version',
  [EnumName.deviceType]: 'Device type',
  [EnumName.deviceAgeDays]: 'Device age days',
  [EnumName.deviceAgeHours]: 'Device age hours',
  [EnumName.deviceAgeDecay]: 'Device age decay',
  [EnumName.applicationName]: 'Application name',
  [EnumName.zipCode]: 'ZIP Code',
  [EnumName.make]: 'Device make',
  [EnumName.model]: 'Device model',
  [EnumName.creativeID]: 'Creative',
  [EnumName.country]: 'Country',
  [EnumName.city]: 'City',
  [EnumName.region]: 'Region',
  [EnumName.hour]: 'Hour',
  [EnumName.losses]: 'Losses',
  [EnumName.impToLosses]: 'ImpToLosses',
  [EnumName.hasPMP]: 'HasPMP',
  [EnumName.dealID]: 'DealID',
  [EnumName.organization]: 'Organization',
  [EnumName.campaignID]: 'Campaign',
  [EnumName.exchange]: 'Exchange',
  [EnumName.day]: 'Day',
  [EnumName.placementID]: 'AdPlacement ID',
  [EnumName.carrier]: 'Carrier',
  [EnumName.hwVer]: 'Hardware version',
  [EnumName.rawPrice]: 'BidCPM',
  [EnumName.impressionDay]: 'Day of impression',
  [EnumName.impressionHour]: 'Hour of impression',
  [EnumName.impressionWeek]: 'Week of impression',
  [EnumName.impressionMonth]: 'Month of impression',
  [EnumName.convDay]: 'Day of install',
  [EnumName.hasIFA]: 'Has IFA',
  [EnumName.roundedBidFloor]: 'Rounded bid floor',
  [EnumName.dspSpend]: 'Spend',
  [EnumName.conversions]: 'Conversions',
  [EnumName.bidCPM]: 'Bid CPM',
  [EnumName.exchangeCategory]: 'Category',
  [EnumName.lat]: 'Latitude',
  [EnumName.lon]: 'Longitude',
  [EnumName.isNewDevice]: 'Is fresh device',
  [EnumName.gender]: 'Gender',
  [EnumName.yob]: 'Year of birth',
  [EnumName.predictorProbability]: 'Predictor probability',
  MaxBidCPM: 'Max BidCPM',
  SwitchBidCPM: 'BidCPM',
  SwitchBFCAP: 'BF+CAP',
  bidFloor: 'Bid Floor',
  BidFloorCap: 'Bid Floor Delta',
  [EnumName.impressions]: 'Impressions',
  [EnumName.clicks]: 'Clicks',
  [EnumName.allClicks]: 'All clicks',
  [EnumName.ecpm]: 'ECPM',
  [EnumName.cpi]: 'CPI',
  [EnumName.ctr]: 'CTR',
  [EnumName.ecpc]: 'ECPC',
  [EnumName.cr]: 'CR',
  [EnumName.advSpend]: 'Revenue',
  [EnumName.dspProfit]: 'Profit',
  [EnumName.rejects]: 'Rejects',
  [EnumName.dspRoi]: 'ROI',
  [EnumName.margin]: 'Margin',
  [EnumName.hourPace]: 'Pace',
  [EnumName.i2C]: 'i2C',
  [EnumName.roas]: 'Roas',
  [EnumName.adRoas]: 'Ad Roas',
  [EnumName.roasPurchases]: 'Roas purchases',
  [EnumName.advertiserID]: 'Advertiser id',
  [EnumName.retentionRate]: 'RR',
  [EnumName.postClicks]: 'PC',
  [EnumName.skanInstalls]: 'SKAN Installs',
  [EnumName.postViews]: 'PV',
  [EnumName.retentionRateEvents]: 'Retention rate events',
  [EnumName.retentionRateInstalls]: 'Retention rate installs PV',
  [EnumName.goal1]: 'G1',
  [EnumName.goal2]: 'G2',
  [EnumName.goal3]: 'G3',
  [EnumName.goal4]: 'G4',
  [EnumName.goal5]: 'G5',
  [EnumName.goal6]: 'G6',
  [EnumName.ecpa1]: 'eCPA1',
  [EnumName.ecpa2]: 'eCPA2',
  [EnumName.ecpa3]: 'eCPA3',
  [EnumName.ecpa4]: 'eCPA4',
  [EnumName.ecpa5]: 'eCPA5',
  [EnumName.ecpa6]: 'eCPA6',
  [EnumName.impPos1h]: 'Imp position in 1h',
  [EnumName.impPos3h]: 'Imp position in 3h',
  [EnumName.impPos12h]: 'Imp position in 12h',
  [EnumName.impPos1d]: 'Imp position in 1d',
  [EnumName.impPos7d]: 'Imp position in 7d',
  [EnumName.purchases]: 'IAP',
  [EnumName.purchasesCount]: 'IAPs',
  [EnumName.winRate]: 'WinRate',
  [EnumName.storeID]: 'Store ID',
  [EnumName.appName]: 'App name',
  [EnumName.currency]: 'Currency',
  [EnumName.creativeUrl]: 'Creative Url',
  [EnumName.productID]: 'Product ID',
  [EnumName.events]: 'Events',
  importantGoals: 'Important goals',
  [EnumName.bids]: 'Bids',
  [EnumName.uniqImpressions]: 'UniqImps',
  [EnumName.uniqImpRate]: 'UniqImpRate',
  [EnumName.uniqClicks]: 'UniqClicks',
  [EnumName.uniqClickRate]: 'UniqClickRate',
  [EnumName.createdAt]: 'Created at',
  [EnumName.budget]: 'Budget',
  [EnumName.totalDspSpend]: 'Total Spend',
  [EnumName.connectionType]: 'Connection type',
  [EnumName.rewarded]: 'Rewarded',
  [EnumName.weekDay]: 'Day of week',
  [EnumName.impHeight]: 'Impression height',
  [EnumName.impWidth]: 'Impression width',
  [EnumName.i2G]: 'i2G',
  [EnumName.bti]: 'BTI',
  [EnumName.hourActivityRatio]: 'Hour activity ratio',
  [EnumName.btit]: 'BTIT',
  [EnumName.overPrice]: 'Over Price',
  [EnumName.targetStatsMap]: 'VAST/MRAID events',
  [EnumName.skadNetworkMap]: 'SKAD Network events',
  [EnumName.erid]: 'ERID',
  [EnumName.goal]: 'Goal ID',
  [EnumName.adRoasPurchases]: 'Ad Roas Purchases',
  adPurchases: 'Ad Purchases',

  [EnumAdType.b]: 'Banner',
  [EnumAdType.n]: 'Native',
  [EnumAdType.v]: 'Video',

  // StaticErrorLocalize
  SomethingWentWrong: 'Something went wrong',
  AnUnknownErrorHasOccurred: 'An unknown error has occurred.',

  // tracker
  Tracker: 'Tracker',
  TrackingLinks: 'Tracking links',
  ClickS2S: 'Click S2S',
  ClickRedirect: 'Click redirect',
  ImpressionRedirect: 'Impression redirect',
  ImpressionS2S: 'Impression S2S',
  CustomBaseURL: 'Custom base URL',
  QueryParams: 'Query params',
  BaseUrlParams: 'Base Url params',

  Reengagement: 'Reengagement',
  ReengagementInterval: 'Reengagement interval',
  MakeReengagmentCampaigns: 'Make reengagement for campaigns',
  SelectCampaignsForReengagment: 'Select campaigns for reengagement',

  Gender: 'Gender',
  Hour: 'Hour',
  Minute: 'Minute',
  WorkingDaysAndHours: 'Working days and hours',

  reklID: 'Advertiser',
  [EnumStatsColumnName.organizationID]: 'Organization',
  [EnumStatsColumnName.creativeHash]: 'Creative hash',
  [EnumStatsColumnName.campaignName]: 'Campaign name',
  [EnumStatsColumnName.timeSeriesAt]: 'Time series at',

  InvalidValue: 'invalid value',
  InvalidDate: 'should be date (eg. 10/29/2019)',

  // installedApplicationGroupList
  RemoveGroup: 'Remove group',

  // Organizations
  Organizations: 'Organizations',
  Amount: 'Amount',
  NoOrganizations: 'No organizations',
  BiddingActive: 'Bidding active',
  CreativeApprove: 'Creative approve',
  NoBillings: 'No payments & bills',

  // Resources
  SyncRatio: 'Sync ratio',
  AspectRatio: 'Aspect ratio',
  LeftTopX: 'Left top X',
  LeftTopY: 'Left top Y',
  Width: 'Width',
  Height: 'Height',
  TargetWidth: 'Resize to width',
  TargetHeight: 'Resize to height',
  VideoStartTime: 'Video start time',
  VideoEndTime: 'Video end time',
  VideoQuality: 'Video quality',
  UseJPEG: 'Use JPEG',
  SkipOptimization: 'Do not make any image optmizations',

  // Creatives
  UnlinkCreatives: 'Unlink creatives',
  CreativeCreate: 'Create creative',
  NoCreative: 'No creatives',
  NoFilterAndCreative: 'Please select parameters to find creatives',
  CreativeResource: 'This creative resources',
  CreativeType: 'Creative type',
  CopyCreative: 'Copy сreative',
  Creative: 'Creative',
  SaveCreative: 'Save creative',
  BlockedOn: 'Blocked on',
  Api: 'Api',
  Preview: 'Preview',
  Minified: 'Minified',
  Hash: 'Hash',
  Template: 'Template',
  CreateTemplate: 'Create template',
  TestingType: 'Testing type',
  IFATestingSymbol: 'Identifier first symbols',
  CopyProductToTheBundle: 'Do you want to make a clone of the product to the bundle?',
  SearchCampaign: 'Search campaign',
  Viewability: 'Viewability',
  FileWidth: 'File width',
  FileHeight: 'File height',
  RandomValue: 'Random value',
  Duration: 'Duration',
  VastVersion: 'Vast version',
  Resource: 'Resource',
  NoMediaFindResource: 'There are no',
  MarkupVariableList: 'Custom variables',
  BindingList: 'Binding list',
  MarkupVariables: 'Markup variables',
  TrackingVariables: 'Tracking variables',
  MarkupTestVariables: 'Markup test variables',
  ResourceListVariables: 'Resource list',
  HideUI: 'Hide UI',
  SelectImageVideoAndResource: 'Images, videos and resources',
  PreviewWithoutCampaignError: 'To preview the creative, you need to have at least one active campaign or select a campaign from the dropdown.',
  // native creative
  NativeCreativeMainImageResourceRequiredTooltip: 'Bid only if request contains image placement',
  NativeCreativeVideoResourceRequiredTooltip: 'Bid only if request contains video placement',
  // [END] Creatuves

  // Markups
  Markup: 'Markup',
  Markups: 'Markups',
  Order: 'Order',
  TestValue: 'Test value',
  DefaultValue: 'Default value',
  SubValidator: 'Sub validator',
  IsBannerVideo: 'Banner video',
  UpdateHash: 'Update hash',
  CheckVariables: 'Check variables',
  IsPlayable: 'Playable',
  NoMarkup: 'No markups',

  BeforeUpdateMarkupTitle: 'Are you sure you want to change the markup?',
  BeforeUpdateMarkup: 'Some settings (eg. variables or file settings) could be removed. You could revert all changes just reloading the page instead of update',
  // [END] Markups

  History: 'History',
  Message: 'Message',
  NoHistory: 'No edit history',
  OnlyManual: 'Only manual',

  TFBlockedByAutopilot: 'Some of these fields may not be editable because the product is on autopilot',
  TFBlockedByPaceKeeper: 'These fields are blocked by pace keeper',
  TFBlockedByColdStart: 'These fields are blocked by cold start',

  PaceKeeper: 'Pace keeper',
  ColdStart: 'Cold start',
  ColdStartMoveToProcessing: 'Start processing',

  // Products
  Autopilot: 'Autopilot',
  StoreLink: 'Store link',
  AllProducts: 'All products',
  ProductEdit: 'Product edit',
  ProductInfo: 'Product info',
  ProductID: 'Product ID',
  FrequencySecondsInterstitial: 'Frequency impression seconds full screen',
  FrequencySecondsNonInterstitial: 'Frequency impression seconds non full screen',
  FrequencyClickSeconds: 'Frequency click seconds',
  BidAfterORD: 'Bid only on synced with ord creatives',
  ISSKADNetwork: 'SKAdNetwork UA',
  Platform: 'Platform',
  AppID: 'App ID',
  UseDeepLink: 'Use deep link',
  RoasProduct: 'ROAS product',
  // [EEND] Products

  // Segment
  NoSegments: 'No segments',
  List: 'List',
  Url: 'Url',
  LinkSegment: 'Link',
  LinkDownloadSegment: '(Download)',
  IsSegment: 'Segment',
  IsMLSet: 'ML Set',
  TestSet: 'Test Set',
  IsSuppression: 'Suppression',
  RefreshFromOrigin: 'Refresh from url',
  RefreshDataDPM: 'Refresh to DMP',
  CreationType: 'Upload ifa <br /> (one ifa per line)',
  // [EEND] Segment

  //  MarkupState
  // [[##END]]  MarkupState

  // Models
  NoModelsTasks: 'No tasks for models',
  NewModelCreation: 'New model creation',
  GoalSpecialCondition: 'Goal special condition',
  ImpressionSpecialCondition: 'Impression special condition',
  TargetGoal: 'Target goal',
  ModelID: 'Model ID',
  TaskID: 'Task ID',
  AUCIs: 'AUC',
  SampleSizeIs: 'Sample size',
  BestIterationIs: 'Best iteration',
  ShowCampaigns: 'Show campaigns',
  NoModels: 'No models',
  ModelName: 'Model name',
  DateFrom: 'Date from',
  DateTo: 'Date to',
  LearnModel: 'Learn model',
  TargetGoalPrice: 'Target goal price',
  SelectedCampaignsForNewModel: 'Selected campaigns for new model',
  TaskForNewModelCreatedSuccessfully: 'Task for new model created successfully',
  UseModel: 'Use model',
  UseOrganizationOwnPredictor: 'Use organization own predictor',

  //  Export
  Frequency: 'Frequency',
  ExportName: 'Export name',
  ExtraEmails: 'Extra emails',
  SaveReport: 'Save report',
  NoData: 'No data',
  ShowAllExport: 'Show all export',
  AllExport: 'All export',
  NoReports: 'No reports',
  ExportModalTitle: 'Choose export report type',
  ReportTypeLogsRawData: 'Raw data for the events (imp, click and conversion) which includes time, IDFA/GAID, IP, ...',
  ReportDayPeriod: 'Report days period',
  ReportOneTimeExport: 'One time export',
  Repeatable: 'Repeatable',
  ExportType: 'Export type',
  MakeReportRepeatable: 'Make this report repeatable',
  DownloadReport: 'Download this report',
  ReportNextRunDate: 'Next run date',
  ReportDaysRange: 'Days range (from -- to)',
  // [##END] Export

  //  Bundles
  Bundle: 'Bundle',
  Bundles: 'Bundles',
  NoBundles: 'No bundles',
  SelectBundle: 'Select bundle',
  NewBundle: 'New bundle',
  [EnumBundleType.web]: 'Web site',
  //  [[###END]] Bundles

  //  Significance
  SelectTwoToCompare: 'Select two parameters to compare',
  BaseParameter: 'Base parameter',
  DerivedParameter: 'Derived parameter',
  // [[##END]] Significance

  // native content validation message
  MainImage: 'Main image',
  Video: 'Video',
  Logo: 'Logo',
  VideoIsRequired: 'Video is required',
  MainImageIsRequired: 'Main image is required',
  Dimension_16x9or9x16: '16:9 or 9:16',
  // native content validation message

  // Goals
  Goals: 'Goals',
  Goal: 'Goal',
  Count: 'Count',
  GoalStatsForWeek: 'Goal stats for week',
  UniqIFA: 'Uniq IFA',
  AddGoal: 'Add Goal',
  // [[###END Goals]]

  Performance: 'Performance',
  SelectIndicators: 'Select indicators',
  AuctionRejects: 'Auction rejects',
  Absolute: 'Absolute',
  Related: 'Related',

  //  ModelHistoryList
  Levels: 'Levels',
  File: 'File',

  CampaignIsNotAllowEditing: 'Campaign editing is not allowed',
  CreativeIsNotAllowEditing: 'Creative editing is not allowed',
  AllMediaFileFiltered: 'All media files are filtered by type of downloadable resource',

  //  Ui customization from user
  Interface: 'Interface',
  Theme: 'Theme',
  HideMasksOnInput: 'Hide input masks on typing',
  WatchAdvertiserValues: 'Watch advertiser values',

  UpdateInCurrentTab: 'Update in current tab',
  OpenInNewTab: 'Open in new tab',

  // Rekl Bundle
  AllowInUILite: 'Allowed in new UI',

  // Campaign creative
  AddToCampaign: 'Add to campaign',
  RemoveFromCampaign: 'Remove from campaign',
  AddOrRemoveFromCampaign: 'Add or remove campaign for creative',

  //  admin summary stats
  Summary: 'Summary',
  Revenue: 'Revenue',
  RevenueDynamics: 'Revenue dynamics',
  Spend: 'Spend',
  Exchange: 'Exchange',
  Genre: 'Genre',
  GenreLevel: 'Level',
  Office: 'Office',
  Division: 'Division',
  GrossRevenue: 'Profit',
  PreviousPeriod: 'Previous period',
  //
  //
  // label settings (a\b testing)
  ABTesting: 'A/B testing',
  TestingRange: 'Testing range',
  TestingLabel: 'Testing label',
  //
  // Seed value 
  SplitSeedValue: 'Split seed value',
  GhostBidding: 'Ghost bidding',
  //
  //
  // DropZone
  MaxFile100mb: 'Max file size 100mb',
  // Dropzone
  enumResourceType: {
    [EnumResourceType.Image]: 'Image',
    [EnumResourceType.Video]: 'Video',
    [EnumResourceType.Resource]: 'Resource',
    [EnumResourceType.Segment]: 'Segment',
    [EnumResourceType.SegmentPart]: 'Segment part',
  },

  enumCreativeApproveStatus: {
    [EnumCreativeApproveStatus.approved]: 'Approved',
    [EnumCreativeApproveStatus.onReview]: 'On Review',
    [EnumCreativeApproveStatus.rejected]: 'Rejected',
  },

  enumMarkupVariableType: {
    [EnumMarkupVariableType.Any]: 'Any',
    [EnumMarkupVariableType.Code]: 'Code',
    [EnumMarkupVariableType.Number]: 'Number',
    [EnumMarkupVariableType.Boolean]: 'Boolean',
    [EnumMarkupVariableType.Integer]: 'Integer',
    [EnumMarkupVariableType.Duration]: 'Duration',
    [EnumMarkupVariableType.Selector]: 'Selector',
    [EnumMarkupVariableType.PositiveNumber]: 'Positive number',
  },

  enumPaceKeeperStatus: {
    [EnumPaceKeeperStatus.None]: 'None',
    [EnumPaceKeeperStatus.Enabled]: 'Enabled',
    [EnumPaceKeeperStatus.Resetting]: 'Resetting',
  },

  enumProductStatus: {
    [EnumProductStatus.active]: 'Active',
    [EnumProductStatus.paused]: 'Paused',
    [EnumProductStatus.archived]: 'Archived',
  },

  enumCampaignStatus: {
    [EnumCampaignStatus.active]: 'Active',
    [EnumCampaignStatus.paused]: 'Paused',
    [EnumCampaignStatus.archived]: 'Archived',
    [EnumCampaignStatus.pausedByRevenueCap]: 'Paused by revenue cap',
  },

  enumAttributionType: {
    [EnumAttributionType.byImpression]: 'Impression',
    [EnumAttributionType.byConv]: 'Install',
    [EnumAttributionType.byEvent]: 'Event',
  },

  enumOrganizationPaymentStatus: {
    [EnumOrganizationPaymentStatus.inProcess]: 'In process',
    [EnumOrganizationPaymentStatus.confirmed]: 'Confirmed',
    [EnumOrganizationPaymentStatus.declined]: 'Decline',
  },

  enumMLearnTaskDtoStatus: {
    [EnumMLearnTaskDtoStatus.New]: 'New',
    [EnumMLearnTaskDtoStatus.InProgress]: 'In Progress',
    [EnumMLearnTaskDtoStatus.Ok]: 'Ok',
    [EnumMLearnTaskDtoStatus.Error]: 'Error',
  },

  enumReportStatus: {
    [EnumReportStatus.success]: 'Success',
    [EnumReportStatus.inProgress]: 'In Progress',
    [EnumReportStatus.failure]: 'Failure'
  },

  enumCreationType: {
    [EnumCreationType.file]: 'From file',
    [EnumCreationType.url]: 'Via url',
    [EnumCreationType.list]: 'Paste (less 10 000 rows)',
  },

  enumSegmentStatus: {
    [EnumSegmentStatus.failure]: 'Failed',
    [EnumSegmentStatus.inProcess]: 'In process',
  },

  enumOrganizationPaymentSource: {
    [EnumOrganizationPaymentSource.stripe]: 'Stripe',
    [EnumOrganizationPaymentSource.creditLinkedNote]: 'Credit',
  },

  enumConnectionType: {
    [EnumConnectionType.unknown]: 'unknown',
    [EnumConnectionType.ethernet]: 'ethernet',
    [EnumConnectionType.wifi]: 'wi-fi',
    [EnumConnectionType.cellularNetworkUnknownGeneration]: 'cellular unknown generation',
    [EnumConnectionType.cellularNetwork2G]: '2G',
    [EnumConnectionType.cellularNetwork3G]: '3G',
    [EnumConnectionType.cellularNetwork4G]: '4G',
  },

  enumTargetingContainerState: {
    [EnumTargetingContainerState.notSpecified]: 'All',
    [EnumTargetingContainerState.whitelist]: 'Whitelist',
    [EnumTargetingContainerState.blacklist]: 'Blacklist',
  },

  enumDeviceType: {
    [EnumDeviceType.all]: 'All',
    [EnumDeviceType.phone]: 'Phone',
    [EnumDeviceType.tablet]: 'Tablet',
  },

  enumCreativeViewabilityProvider: {
    [EnumCreativeViewabilityProvider.ignore]: 'Ignore',
    [EnumCreativeViewabilityProvider.none]: 'No viewability',
    [EnumCreativeViewabilityProvider.any]: 'MOAT or IAS',
    [EnumCreativeViewabilityProvider.moat]: 'MOAT',
    [EnumCreativeViewabilityProvider.ias]: 'IAS',
  },

  enumInitialAttributionTypeList: {
    [EnumInitialAttributionTypeList.originalInstall]: 'User acquisition',
    [EnumInitialAttributionTypeList.reattribution]: 'Retargeting',
    [EnumInitialAttributionTypeList.reengagement]: 'Reengagement',
  },

  enumProductInitialAttributionTypeList: {
    [EnumProductInitialAttributionType.UserAcquisition]: 'User acquisition',
    [EnumProductInitialAttributionType.RetargetingAndReengagement]: 'Retargeting and Reengagement',
  },

  enumGender: {
    [EnumGender.f]: 'Female',
    [EnumGender.m]: 'Male',
  },

  enumProductType: {
    [EnumProductType.Media]: 'Media',
    [EnumProductType.PerformanceCpa]: 'Performance CPA',
    [EnumProductType.PerformanceRoas]: 'Performance Roas',
  },

  enumFilterEntityType: {
    [EnumFilterEntityType.Equals]: '=',
    [EnumFilterEntityType.GreaterThan]: '>',
    [EnumFilterEntityType.In]: 'IN LIST',
    [EnumFilterEntityType.LessThan]: '<',
    [EnumFilterEntityType.Like]: 'LIKE',
    [EnumFilterEntityType.GreaterOrEqualsThan]: '>=',
    [EnumFilterEntityType.LessOrEqualsThan]: '<=',
  },

  enumUserRole: {
    [EnumUserRole.administrator]: 'Administrator',
    [EnumUserRole.rekl]: 'Advertiser',
    [EnumUserRole.buyer]: 'Buyer',
  },

  enumStatsColumnName: {
    [EnumStatsColumnName.impWinRate]: 'Imp Win Rate',
    [EnumStatsColumnName.creativeName]: 'Creative name',
  },

  enumTrackerCallType: {
    [EnumTrackerCallType.Redirect]: 'Redirect',
    [EnumTrackerCallType.S2S]: 'S2S integration',
  },
};
