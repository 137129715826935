import isEqual from 'lodash-es/isEqual';

import { getLocation } from 'helpers/dom';

import routes from 'constants/routes';

import { IGetState } from 'models/utils';

import { TypedDispatch } from 'store';

import types from 'actions/types';
import { productStatsListRequest } from 'actions/product';
import { campaignListStatsRequest, campaignStatsGroupingRequest } from 'actions/campaigns';

import { IAction } from './types';

const useReklFiguresMiddleware = ({ dispatch }: { getState: IGetState, dispatch: TypedDispatch }) => (next: any) => (action: IAction<any>) => {
  const result = next(action);
  if (result.type === types.UI_CHANGE_VALUE) {
    const val = result.payload.valueList?.[0];
    if (val && isEqual(val.key, ['globalStatsFilter', 'useReklFigures'])) {
      const location = getLocation();
      // campaign list
      if (location.pathname === routes.campaigns) {
        dispatch(campaignListStatsRequest());
      }

      // campaign detail
      if (location.pathname.startsWith(routes.campaignsDetail)) {
        const cmapaignIDList = JSON.parse(decodeURIComponent(location.pathname.split(routes.campaignsDetail + '/')[1]));
        dispatch(campaignStatsGroupingRequest(cmapaignIDList));
      }

      // advertiser list
      if (location.pathname === routes.advertisers) {
        dispatch(productStatsListRequest());
      }
    }
  }
  return result;
};

export default useReklFiguresMiddleware;
