import { IGetState } from 'models/utils';

import { TypedDispatch } from 'store';

import API from 'constants/api';

import { request } from './utils';

export function dictionaryListRequest() {
  return (dispatch: TypedDispatch, getState: IGetState) => (
    request({ api: API.dictionaryListRequestDto, requestCancel: false })(dispatch, getState)
  );
}
