import { createBrowserRouter } from 'react-router-dom';

import BaseRouter from 'components/BaseRouter';
import PrivateRouter from 'components/PrivateRouter';
//
import routes from 'constants/routes';
//
import * as M from './imports';

import { store } from 'store';
import types from 'actions/types';

export const router = createBrowserRouter([
  {
    path: routes.login,
    element: <BaseRouter><M.Auth /></BaseRouter>,
  },
  {
    path: routes.serviceConfirmPassword,
    element: <BaseRouter><M.ServiceRestorePassword /></BaseRouter>,
  },
  {
    path: `${routes.serviceNoUser}/:errorCode`,
    element: <BaseRouter noRedirect={true}><M.ServiceNoResponseUser /></BaseRouter>,
  },
  {
    path: routes.billingStatus,
    element: <BaseRouter noRedirect={true}><M.ServiceBillingStatus /></BaseRouter>,
  },
  {
    path: routes.campaigns,
    element: <PrivateRouter><M.CampaignIndex /></PrivateRouter>,
  },
  {
    path: `${routes.campaignsCreate}/:menuState`,
    element:<PrivateRouter permission='campaigns.create'><M.CampaignCreate /></PrivateRouter>,
  },
  {
    path: `${routes.campaignsDetail}/:campaignIDList`,
    element: <PrivateRouter><M.CampaignDetail /></PrivateRouter>,
  },
  {
    path: `${routes.campaignsEdit}/:campaignID/:menuState`,
    element: <PrivateRouter permission='campaigns.campaignSettingsView'><M.CampaignEdit /></PrivateRouter>,
  },
  {
    path: routes.creatives,
    element: <PrivateRouter><M.CreativesIndex /></PrivateRouter>,
  },
  {
    path: `${routes.advertiser}/:reklID${routes.creatives}`,
    element: <PrivateRouter><M.CreativesIndexByRekl /></PrivateRouter>,
  },
  {
    path: `${routes.campaign}/:campaignID${routes.creatives}`,
    element: <PrivateRouter><M.CreativesIndexByCampaign /></PrivateRouter>,
  },
  {
    path: `${routes.creativesCreate}/:reklID`,
    element: <PrivateRouter permission='creatives.create'><M.CreativesCreate /></PrivateRouter>,
  },
  {
    path: `${routes.creativesEdit}/:creativeID`,
    element: <PrivateRouter><M.CreativesEdit /></PrivateRouter>,
  },
  {
    path: routes.creativesApprove,
    element: <PrivateRouter permission='creatives.approveAction'><M.CreativeApprove /></PrivateRouter>,
  },
  {
    path: `${routes.creativesPreview}/:creativeID`,
    element: <PrivateRouter><M.CreativePreview /></PrivateRouter>,
  },
  {
    path: routes.userSettings,
    element: <PrivateRouter><M.UserSettings /></PrivateRouter>,
  },
  {
    path: routes.advertisers,
    element: <PrivateRouter permission='advertisers.routerList'><M.Advertisers /></PrivateRouter>,
  },
  {
    path: routes.advertiserCreate,
    element: <PrivateRouter permission='advertisers.create'><M.AdvertiserCreate /></PrivateRouter>,
  },
  {
    path: `${routes.advertiser}/:reklID`,
    element: <PrivateRouter permission='advertisers.routerDetail'><M.AdvertisersDetail /></PrivateRouter>,
  },
  {
    path: routes.markups,
    element: <PrivateRouter permission='markups.routerList'><M.Markups /></PrivateRouter>,
  },
  {
    path: routes.markupsCreate,
    element: <PrivateRouter permission='markups.create'><M.MarkupsCreate /></PrivateRouter>,
  },
  {
    path: `${routes.markupsEdit}/:markupID`,
    element: <PrivateRouter permission='markups.edit'><M.MarkupsEdit /></PrivateRouter>,
  },
  {
    path: routes.segments,
    element: <PrivateRouter permission='segments.view'><M.Segments /></PrivateRouter>,
  },
  {
    path: routes.reports,
    element: <PrivateRouter permission='reports.routerList'><M.Reports /></PrivateRouter>,
  },
  {
    path: `${routes.history}/:type/:elementID`,
    element: <PrivateRouter permission='history.routerList'><M.History /></PrivateRouter>,
  },
  {
    path: routes.modelTasks,
    element: <PrivateRouter permission='models.modelTasksPage'><M.ModelTasks /></PrivateRouter>,
    children: [
      {
        path: ':bundleID',
        element: <PrivateRouter permission='models.modelTasksPage'><M.ModelTasks /></PrivateRouter>,
      },
    ],
  },
  {
    path: `${routes.productEdit}/:productID/:menuState`,
    element: <PrivateRouter permission='product.edit'><M.ProductEdit /></PrivateRouter>,
  },
  {
    path: `${routes.productCreate}/:menuState`,
    element: <PrivateRouter permission='product.create'><M.ProductCreate /></PrivateRouter>,
  },
  {
    path: routes.organizations,
    element: <PrivateRouter permission='organization.organizationList'><M.OrganizationIndex /></PrivateRouter>,
  },
  {
    path: `${routes.organizationsEdit}/:organizationID`,
    element: <PrivateRouter permission='organization.organizationEdit'><M.OrganizationShow /></PrivateRouter>,
  },
  {
    path: `${routes.organizationsView}/:organizationID`,
    element: <PrivateRouter permission='organization.organizationView'><M.OrganizationShow /></PrivateRouter>,
  },
  {
    path: routes.organizationCreate,
    element: <PrivateRouter permission='organization.organizationCreate'><M.OrganizationCreate /></PrivateRouter>,
  },
  {
    path: routes.bundles,
    element: <PrivateRouter permission='bundles.menu'><M.Bundles /></PrivateRouter>,
  },
  {
    path: `${routes.bundleCreate}/:reklID`,
    element: <PrivateRouter permission='bundles.menu'><M.BundleCreate /></PrivateRouter>,
  },
  {
    path: `${routes.advertiserBundle}/:reklBundleID`,
    element: <PrivateRouter permission='reklBundles.menu'><M.AdvertiserBundleIndex /></PrivateRouter>,
  },
  {
    path: `${routes.advertiserBundleEdit}/:reklBundleID`,
    element:<PrivateRouter permission='reklBundles.edit'><M.AdvertiserBundleEdit /></PrivateRouter>,
  },
  {
    path: routes.billing,
    element: <PrivateRouter permission='billing.billingPage'><M.BillingIndex /></PrivateRouter>,
  },
  {
    path: routes.summary,
    element: <PrivateRouter permission='summary.view'><M.SummaryIndex /></PrivateRouter>,
  },
  {
    path: routes.usersManager,
    element: <PrivateRouter permission='user.routerManager'><M.UsersManager /></PrivateRouter>,
  },
  {
    path: '*',
    element: <PrivateRouter><M.NotFound /></PrivateRouter>,
  },
]);

// TODO: private method
router.subscribe(state => {
  store.dispatch({ type: types.LOCATION_CHANGE, payload: state.location });
});
