import { fmtNumber } from '@bidease/ui-kit';
import orderBy from 'lodash-es/orderBy';
import escapeRegExp from 'lodash-es/escapeRegExp';
import { IInObjectDto } from 'helpers/utils';
import { EnumMenuCampaignState } from 'models/campaigns';
import {
  CampaignBidDto,
  CampaignChangeSetEntityDto,
  CampaignDto,
  CampaignStatsDto,
  CampaignStatsOrderingDto,
  EnumCampaignState,
  EnumChangeSetAction,
  EnumGroupingKey,
  EnumOrderingDirection,
  EnumStatsColumnName,
  EnumProperty,
  EnumTargetingContainerState,
  TargetingDto,
  TargetingZipDto,
  TargetingConnectionTypeDto,
  TargetingIABCategoryDto,
  TargetingExchangeDto,
  TargetingCountryDto,
  TargetingUserAgentDto,
  TargetingLanguageDto,
  TargetingSspDto,
  TargetingOperationSystemVersionDto, PredictorCampaignMultiplierAdjDto,
} from 'models/objects';

import { EnumListName, EnumFilterURLList } from 'constants/enum';
import { bidCPMUpdater } from 'constants/fixture';
import routes from 'constants/routes';
import localize from 'constants/localize';

export const blackToWhiteOrNot = (targeting: TargetingDto, obj: CampaignStatsDto, property: EnumStatsColumnName) => {
  const nameList = property + 'List';
  if (targeting && nameList in targeting) {
    const { targetingContainerState, content } = targeting[property + 'List' as keyof TargetingDto];
    const remove = EnumChangeSetAction.remove;

    if (!targetingContainerState || !content) {
      return null;
    }

    if (targetingContainerState === EnumTargetingContainerState.notSpecified) {
      return remove;
    }

    if (Object.prototype.hasOwnProperty.call(targeting, nameList)) {
      const findValue = () => {
        const vl = obj[property];
        switch (nameList) {
          case EnumListName.zipList:
            return content.find((q: TargetingZipDto) => q.zip === vl);
          case EnumListName.connectionTypeList:
            return content.find((q: TargetingConnectionTypeDto) => q.connectionType === vl);
          case EnumListName.iabCategoryList:
            return content.find((q: TargetingIABCategoryDto) => q.iabCategoryID === vl);
          case EnumListName.exchangeList:
            return content.find((q: TargetingExchangeDto) => q.code === vl);
          case EnumListName.countryList:
            return content.find((q: TargetingCountryDto) => q.countryID === vl);
          case EnumListName.userAgentList:
            return content.find((q: TargetingUserAgentDto) => q.keyword === vl);
          case EnumListName.languageList:
            return content.find((q: TargetingLanguageDto) => q.languageID === vl);
          case EnumListName.sspList:
            return content.find((q: TargetingSspDto) => q.sspID === vl);
          case EnumListName.operationSystemVersionList:
            return content.find((q: TargetingOperationSystemVersionDto) => q.min === vl);
          default:
            return content.find((q: { name: string }) => q.name === vl);
        }
      };

      const found = !!findValue();

      if (targetingContainerState === EnumTargetingContainerState.whitelist && found) {
        return remove;
      }
      if (targetingContainerState === EnumTargetingContainerState.blacklist && !found) {
        return remove;
      }
    }
    return EnumChangeSetAction.add;
  }

  return null;
};

export const changeSet = {
  // изменение состояния кампании
  campaignState: (listID: number[], campaignState: EnumCampaignState): CampaignChangeSetEntityDto[] => (
    listID.map(e => ({
      campaignID: e,
      property: EnumProperty.campaignState,
      action: EnumChangeSetAction.save,
      value: JSON.stringify({ [EnumProperty.campaignState]: campaignState }),
    }))
  ),
  // глаз внутри детального списка кампаний
  eyeClick: (
    campaign: CampaignDto,
    property: keyof TargetingDto,
    value: string | number | boolean,
    action: EnumChangeSetAction
  ) => {
    const getValue = () => {
      if (campaign.targeting[property]?.content) {
        switch (property) {
          case EnumListName.connectionTypeList:
            return JSON.stringify(campaign.targeting.connectionTypeList.content.find(q => q.connectionType === value) || { connectionType: value });
          case EnumListName.iabCategoryList:
            return JSON.stringify(campaign.targeting.iabCategoryList.content.find(q => q.iabCategoryID === value) || { iabCategoryID: value });
          case EnumListName.exchangeList:
            return JSON.stringify(campaign.targeting.exchangeList.content.find(q => q.code === value) || { code: value });
          case EnumListName.countryList:
            return JSON.stringify(campaign.targeting.countryList.content.find(q => q.countryID === value) || { countryID: value });
          case EnumListName.userAgentList:
            return JSON.stringify(campaign.targeting.userAgentList.content.find(q => q.keyword === value) || { keyword: value });
          case EnumListName.languageList:
            return JSON.stringify(campaign.targeting.languageList.content.find(q => q.languageID === value) || { languageID: value });
          case EnumListName.sspList:
            return JSON.stringify(campaign.targeting.sspList.content.find(q => q.sspID === value) || { sspID: value });
          case EnumListName.operationSystemVersionList:
            return JSON.stringify(campaign.targeting.operationSystemVersionList.content.find(q => q.min === value) || { min: value, max: value });
          default:
            return JSON.stringify(campaign.targeting[property]?.content.find((q: any) => q.name === value) || { name: value });
        }
      }
      return JSON.stringify({ name: value });
    };
    return { campaignID: campaign.campaignID, property, value: getValue(), action };
  },
  // редактирование бидспм в аппах
  bidCpmAppTag: (
    campaignID: number,
    app: CampaignStatsDto,
    bid: CampaignBidDto,
    action: EnumChangeSetAction
  ) => ({
    campaignID,
    property: EnumProperty.bidCpmAppTag,
    value: action === EnumChangeSetAction.remove
      ? JSON.stringify({ ...app, bid: { type: bid.type, ...bidCPMUpdater } })
      : JSON.stringify({ ...app, bid }),
    action,
  }),
  // редактирование бидспм в аппах
  bidCpm: (campaignID: number, bid: CampaignBidDto, action: EnumChangeSetAction): CampaignChangeSetEntityDto[] => ([{
    campaignID,
    property: EnumProperty.bidCpm,
    value: JSON.stringify(bid),
    action,
  }]),
  // редактирование колонок в таблице кампаний
  editTableValue: (campaignID: number, property: EnumProperty, value: number): CampaignChangeSetEntityDto[] => ([{
    campaignID,
    property,
    action: EnumChangeSetAction.save,
    value: JSON.stringify({ [property]: fmtNumber.cleanValue(value) }),
  }]),
  editPredictorCampaignMultiplierAdj: (campaignID: number, value: PredictorCampaignMultiplierAdjDto): CampaignChangeSetEntityDto[] => [{
    campaignID,
    property: EnumProperty.predictorMultiplier,
    action: EnumChangeSetAction.save,
    value: JSON.stringify(value)
  }],
  // состояние кампании
  toggleCampaignState: ({ campaignID, basicInfo }: CampaignDto): CampaignChangeSetEntityDto[] => {
    if ([EnumCampaignState.pausedByRevenueCap, EnumCampaignState.paused].includes(basicInfo.campaignState)) {
      return [{
        campaignID: campaignID,
        property: EnumProperty.campaignState,
        action: EnumChangeSetAction.save,
        value: JSON.stringify({ [EnumProperty.campaignState]: EnumCampaignState.active }),
      }];
    }
    return [{
      campaignID: campaignID,
      property: EnumProperty.campaignState,
      action: EnumChangeSetAction.save,
      value: JSON.stringify({ [EnumProperty.campaignState]: EnumCampaignState.paused }),
    }];
  },
};

export const checkDisabled = (campaign: CampaignDto) => campaign.basicInfo.campaignState === EnumCampaignState.paused || campaign.basicInfo.campaignState === EnumCampaignState.pausedByRevenueCap;

export const GroupingSkipList = {
  groupingCampaign: [EnumStatsColumnName.createdAt, EnumStatsColumnName.budget, EnumStatsColumnName.totalDspSpend],
  statsColView: [EnumStatsColumnName.createdAt, EnumStatsColumnName.budget],
};

export const campaignLink = {
  edit: (campaignID: number, path?: EnumMenuCampaignState) => (
    `${routes.campaignsEdit}/${campaignID}/${path || EnumMenuCampaignState.basicInfo}`
  ),
  history: (campaignID: number) => `${routes.history}/campaign/${campaignID}`,
  create: (path?: EnumMenuCampaignState): string => `${routes.campaignsCreate}/${path || EnumMenuCampaignState.basicInfo}`,
  creativeList: (campaignID: number) => `${routes.campaign}/${campaignID}${routes.creatives}`,
};

export const getOrderingCampaignData = (
  campaignList: IInObjectDto<CampaignDto>,
  nameContains: string,
  listsOrdering: CampaignStatsOrderingDto
): CampaignDto[] => {
  const regexpSearch = (val: string) => new RegExp(escapeRegExp(nameContains).trim(), 'i').test(val);
  const { orderingDirection, orderingKey } = listsOrdering.orderingList[0];
  return orderBy(campaignList, [orderingKey || EnumGroupingKey.campaignID] as any, [orderingDirection || EnumOrderingDirection.desc])
    .filter(q => regexpSearch(`${q.campaignID}|${q.basicInfo.name}|${q.basicInfo.description}|${q.productID}`));
};

export const shortCampaignsLink = (campaignList: number[]) => `
${window.location.origin}${routes.campaigns}?${EnumFilterURLList.statsFilter_campaignIDList}=[${campaignList}]&${EnumFilterURLList.statsFilter_campaignStates}=${EnumCampaignState.paused},${EnumCampaignState.active}
`;

export const getCampaignTitle = (campaign: CampaignDto, isRekl: boolean) => {
  if (!campaign) {
    return localize.NullableValue;
  }

  if (isRekl) {
    return campaign.basicInfo?.clientTitle || campaign.basicInfo?.name;
  }

  return campaign.basicInfo?.name;
};
