import { cdeebeeMergeStrategy } from '@recats/cdeebee';

import { TypedDispatch } from 'store';

import { deepFullMerge, normalizeAndGetExtension } from 'normalize/helpers';
import { defaultNormalizeGlobalStatsFilter } from 'normalize/ui';

import {
  CleanServerResponse,
  ProductCopyRequestDto,
  ProductDto,
  ProductListRequestDto,
} from 'models/objects';

import { productLink } from 'shorthands/products';

import { IBasicProductValidation } from 'validation/product';

import API from 'constants/api';
import { defaultMergeListStrategy, EnumListName } from 'constants/enum';

import { IGetState } from 'models/utils';

import { router } from 'routers';

import { getLocation } from 'helpers/dom';
import { OmitDefaultRequestValue } from 'helpers/utils';

import { request } from './utils';

export function productUpdateObject(product: ProductDto, fn?: (productID: number) => void) {
  return (dispatch: TypedDispatch, getState: IGetState) => {
    request({
      api: API.productUpdateRequestDto,
      data: { product },
      normalize: deepFullMerge([EnumListName.reklList]),
      postUpdate: (response: CleanServerResponse) => fn && fn(response.productList.data[0].productID),
    })(dispatch, getState);
  };
}

export function productStatsListRequest(fn?: () => void) {
  return (dispatch: TypedDispatch, getState: IGetState) => {
    const filters = defaultNormalizeGlobalStatsFilter(getState().ui, getLocation().pathname);
    request({
      api: API.productStatsListRequestDto,
      data: filters,
      normalize: normalizeAndGetExtension,
      mergeListStrategy: {
        ...defaultMergeListStrategy,
        [EnumListName.productList]: cdeebeeMergeStrategy.replace,
        [EnumListName.reklList]: cdeebeeMergeStrategy.replace,
      },
      postUpdate: () => fn && fn(),
    })(dispatch, getState);
  };
}

export function productListRequest(data: { productIDList: number[], reklBundleIDList: number[] }) {
  return (dispatch: TypedDispatch, getState: IGetState) => {
    request({
      api: API.productListRequestDto,
      data: data as OmitDefaultRequestValue<ProductListRequestDto>,
    })(dispatch, getState);
  };
}

export function productCreateRequest(product: IBasicProductValidation) {
  return (dispatch: TypedDispatch, getState: IGetState) => {
    request({
      api: API.productCreateRequestDto,
      data: { product },
      postUpdate: (response: CleanServerResponse) => router.navigate(productLink.edit(response.productList.data[0].productID)),
    })(dispatch, getState);
  };
}

export function productCopy(productID: number, reklBundleID: number) {
  return (dispatch: TypedDispatch, getState: IGetState) => {
    request({
      api: API.productCopyRequestDto,
      data: { productID, reklBundleID } as OmitDefaultRequestValue<ProductCopyRequestDto>,
      postUpdate: (response: CleanServerResponse) => (
        window.open(`${window.location.origin}${productLink.edit(response.productList.data[0].productID)}`, '_blank'
        )
      ),
    })(dispatch, getState);
  };
}
