import { indexOf, slice, uniq } from 'lodash-es';
import { Dispatch } from 'redux';
import { cdeebeeHelpers, cdeebeeValueList } from '@recats/cdeebee';

import { FrequencyType, IUICampaignSettings, IUiGlobalStatsFilter } from 'models/ui';
import {
  CampaignDto,
  CampaignStatsFilterDto,
  CampaignStatsGroupDto,
  CampaignStatsOrderingDto,
  ConversionSettingsDto,
  EnumColumnDataTableTypeList
} from 'models/objects';
import { IGetState, IStorage } from 'models/utils';

import { Regexp } from 'constants/service';
import { EnumFilterURLList, EnumItemPerPage } from 'constants/enum';

import { normalizeUrlFilter } from 'normalize/ui';

import { getLocation } from 'helpers/dom';
import { getUrlParam, replaceSpace } from 'helpers/utils';

import { EnumColumnDataTypeListToReduxStore } from 'hooks/useTableMassCheckboxSelection';

import { getOrderingCampaignData } from 'shorthands/campaigns';

import * as campaignsActions from './campaigns';
import * as productActions from './product';

import types from './types';

const requestByFreqType = {
  [FrequencyType.frequencyList]: (dispatch: Dispatch, getState: IGetState) => (
    campaignsActions.campaignListStatsRequest()(dispatch, getState)
  ),
  [FrequencyType.frequencyAdvertisers]: (dispatch: Dispatch, getState: IGetState) => (
    productActions.productStatsListRequest()(dispatch, getState)
  ),
  [FrequencyType.frequencyDetail]: (dispatch: Dispatch, getState: IGetState) => {
    const { pathname } = getLocation();
    const path = decodeURIComponent(pathname);
    const campaignsID = path.match(Regexp.campaignID)
      ? JSON.parse(path.match(Regexp.campaignID)![0])
      : [];
    campaignsActions.campaignStatsGroupingRequest(campaignsID)(dispatch, getState);
  },
};

export function onShiftSelectionList(firstElement: number, clickedElementID: number, _: number, type: EnumColumnDataTableTypeList) {
  return (dispatch: Dispatch, getState: IGetState) => {
    // @ts-ignore TODO:
    const storageArray: number[] = getState().ui.globalStatsFilter[EnumColumnDataTypeListToReduxStore[type]];

    const onSaveCall = (value: number[]) => dispatch({ type: types.UI_CHANGE_VALUE, payload: { valueList: [{ key: ['globalStatsFilter', EnumColumnDataTypeListToReduxStore[type]], value }] } });

    if (type === EnumColumnDataTableTypeList.campaignList) {
      const { cdeebee: { campaignList }, ui: { globalStatsFilter } }: IStorage = getState();
      const { listsOrdering, nameContains } = globalStatsFilter;
      const allowSelection = getOrderingCampaignData(campaignList, nameContains, listsOrdering).map((campaign: CampaignDto) => campaign.campaignID);
      const firstIndex = indexOf(allowSelection, firstElement);
      const lastIndex = indexOf(allowSelection, clickedElementID);
      const value = firstIndex > lastIndex ? slice(allowSelection, lastIndex + 1, firstIndex) : slice(allowSelection, firstIndex, lastIndex + 1);
      onSaveCall(uniq([...value, ...storageArray]));
    } else if (type === EnumColumnDataTableTypeList.campaignDetails) {
      const arrayFrom = (f: number, l: number) => Array.from(Array(f).keys()).slice(l);
      const selections = clickedElementID > firstElement ? arrayFrom(clickedElementID + 1, firstElement) : arrayFrom(firstElement, clickedElementID);
      onSaveCall(uniq([...storageArray, ...selections]).sort());
    }
  };
}

export function onChangeValue(valueList: cdeebeeValueList[]) {
  return (dispatch: Dispatch) => {
    dispatch({ type: types.UI_CHANGE_VALUE, payload: { valueList } });
  };
}

export function onFrequencyRequest(frequencyType: FrequencyType) {
  return (dispatch: Dispatch, getState: IGetState) => {
    requestByFreqType[frequencyType](dispatch, getState);
  };
}

export const shortGlobalStatsFilterUI = (
  {
    statsOrdering, statsFilter, statsGroup, fromDate, toDate, nameContains,
  }: {
    fromDate: string;
    toDate: string;
    statsOrdering: CampaignStatsOrderingDto;
    statsFilter: CampaignStatsFilterDto;
    statsGroup: CampaignStatsGroupDto;
    nameContains: string;
  }
) => replaceSpace(`
${normalizeUrlFilter.set(EnumFilterURLList.fromDate, fromDate, '')}
${normalizeUrlFilter.set(EnumFilterURLList.toDate, toDate)}
${normalizeUrlFilter.set(EnumFilterURLList.nameContains, nameContains)}
${normalizeUrlFilter.set(EnumFilterURLList.statsOrdering_orderingKey, statsOrdering.orderingList[0].orderingKey)}
${normalizeUrlFilter.set(EnumFilterURLList.listOrdering_orderingDirection, statsOrdering.orderingList[0].orderingDirection)}
${normalizeUrlFilter.set(EnumFilterURLList.statsGrouping_groupingKeyList, statsGroup.groupingKeyList)}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_attributionType, statsFilter.attributionType)}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_campaignIDList, statsFilter.campaignIDList)}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_campaignStates, statsFilter.campaignStates)}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_managerIDList, statsFilter.managerIDList)}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_filterList, statsFilter.filterList.filter(Boolean))}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_nativeProduct, statsFilter.includeInactiveProducts)}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_archivedCampaign, statsFilter.archivedCampaigns)}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_reklIDList, statsFilter.reklIDList)}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_productList, statsFilter.productIDList)}
${normalizeUrlFilter.set(EnumFilterURLList.statsFilter_targetingContainerState, statsFilter.targetingContainerState)}
`);

export const defaultGlobalStatsFilterURI = (
  globalStatsFilter: IUiGlobalStatsFilter,
  campaignSettings: IUICampaignSettings,
  conversionSettings: ConversionSettingsDto
) => replaceSpace(`
${shortGlobalStatsFilterUI({ fromDate: globalStatsFilter.fromDate, toDate: globalStatsFilter.toDate, nameContains: globalStatsFilter.nameContains, statsOrdering: globalStatsFilter.statsOrdering, statsFilter: globalStatsFilter.statsFilter, statsGroup: globalStatsFilter.statsGroup })}
${normalizeUrlFilter.set(EnumFilterURLList.listOrdering_orderingKey, globalStatsFilter.listsOrdering.orderingList[0].orderingKey)}
${normalizeUrlFilter.set(EnumFilterURLList.listOrdering_orderingDirection, globalStatsFilter.listsOrdering.orderingList[0].orderingDirection)}

${normalizeUrlFilter.set(EnumFilterURLList.retentionDays, conversionSettings.retentionDays)}
${normalizeUrlFilter.set(EnumFilterURLList.roasDays, conversionSettings.roasDays)}

${normalizeUrlFilter.set(EnumFilterURLList.cmpGraphOpen, campaignSettings.graphOpen)}

&${EnumFilterURLList.page}=${getUrlParam('page') || 1}
&${EnumFilterURLList.count}=${getUrlParam('count') || EnumItemPerPage}
`);

export function getGlobalStatsFilterURI() {
  return (_: Dispatch, getState: IGetState) => {
    const { ui: { globalStatsFilter, campaignSettings, conversionSettings } }: IStorage = getState();
    return defaultGlobalStatsFilterURI(globalStatsFilter, campaignSettings, conversionSettings);
  };
}

export function customizeGlobalStatsURL(valueList: cdeebeeValueList[]) {
  return (_: Dispatch, getState: IGetState) => {
    const { ui: { globalStatsFilter, campaignSettings, conversionSettings } }: IStorage = getState();
    const newGlobalStatsFilter = cdeebeeHelpers.batchingUpdate(globalStatsFilter, valueList) as IUiGlobalStatsFilter;
    return defaultGlobalStatsFilterURI(newGlobalStatsFilter, campaignSettings, conversionSettings);
  };
}

export function setGlobalStatsFilterURI() {
  return (dispatch: Dispatch, getState: IGetState) => {
    // NOTE: we use window.history.replaceState() because its does not call re-rendering of the page
    window.history.replaceState(
      {}, '', `${getLocation().pathname}?${getGlobalStatsFilterURI()(dispatch, getState)}`
    );
  };
}
